import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import jwtDecode, { JwtPayload } from 'jwt-decode'

export default function getVisitorId(
  cookie = document?.cookie
): string | undefined {
  return pipe(
    cookie
      ?.split(';')
      .map(s => s.trim())
      .map(item => {
        const [key, value] = item.split('=')
        return { [key]: value }
      })
      .filter(item => item['atat_v2'])
      .reduce((_acc, item) => item, {}),
    R.lookup('atat_v2'),
    O.map(t => jwtDecode<JwtPayload>(t)),
    O.chain(t => O.fromNullable(t.sub)),
    O.toUndefined
  )
}
