import React, { ReactElement, ReactNode } from 'react'

export type CartWidgetsProps = {
  readonly cartMonitoringPlan?: ReactElement
  readonly affirmCard?: ReactNode
  readonly tryItTestItCard?: ReactNode
  readonly customerServiceCard?: ReactNode
}

function CartWidgets({
  cartMonitoringPlan,
  tryItTestItCard,
  affirmCard,
  customerServiceCard
}: CartWidgetsProps) {
  return (
    <div className="block md:hidden lg:block">
      <div>
        {cartMonitoringPlan && (
          <div className="mb-[32px] block">{cartMonitoringPlan}</div>
        )}

        {tryItTestItCard && (
          <div className="mb-[32px] block">{tryItTestItCard}</div>
        )}

        {affirmCard && <div className="mb-[32px] block">{affirmCard}</div>}

        {customerServiceCard && (
          <div className="mb-[32px] block">{customerServiceCard}</div>
        )}
      </div>
    </div>
  )
}

export default CartWidgets
